function openLightbox(initialSlide, data, type = "instagram") {
  const detail = {
    initialSlide,
    data,
    type
  };
  const event = new CustomEvent("open-lightbox", {
    detail
  });
  window.dispatchEvent(event);
}
function closeLightbox() {
  const event = new Event("close-lightbox");
  window.dispatchEvent(event);
}

export { closeLightbox, openLightbox };
