// @ts-check
import {
  annotateAndLogError,
  evaluateObject,
  getSanitizedError,
  initTheme,
} from "@front10/utils";
import * as logger from "loglevel";
import validateCmpConfig from "../../validateCmpConfig";

/**
 * Extract main component metadata to pass it down to the container
 * @param {*} cmpConfig
 */
const getComponentMetaData = (cmpConfig) => {
  return {
    name: cmpConfig.name,
    moduleId: cmpConfig.moduleId,
    version: cmpConfig.version,
    staticAssetsHost: cmpConfig.staticAssetsHost,
    tag: cmpConfig.tag,
  };
};

export const withCommonPreloads = (
  preloadCustomPropsExecute,
  cmpConfig,
  initialFaresPropertyName
) => {
  return new Promise((resolve, reject) => {
    // Same in all preloads
    const cmpConfigValidation = validateCmpConfig(cmpConfig);
    if (!cmpConfigValidation.isValid) {
      logger.error(cmpConfigValidation.message, cmpConfig);
      reject(cmpConfigValidation.message);
    }

    cmpConfig.containerConfig.settings.meta = getComponentMetaData(cmpConfig);

    const prePopulation = Object.assign(
      {},
      cmpConfig.containerConfig.settings.prePopulation
    );
    // patch to solve EM issue with the evaluation of endpoints
    if (prePopulation.airlineIataCode) {
      prePopulation.airlineCode = prePopulation.airlineIataCode;
    }

    cmpConfig.containerConfig.settings.integrationSettings = evaluateObject(
      cmpConfig.containerConfig.settings.integrationSettings,
      prePopulation
    );
    // cmpConfig.containerConfig.settings.translate = translate;

    preloadCustomPropsExecute(cmpConfig, [], [], initialFaresPropertyName)
      .then((resultCmpConfig) => {
        const themeConfig = { ...resultCmpConfig.containerConfig.theme };
        delete resultCmpConfig.containerConfig.theme;
        const theme = initTheme(themeConfig);
        resultCmpConfig.containerConfig.settings.theme = theme;
        resolve(resultCmpConfig);
      })
      .catch((err) => {
        if (!cmpConfig) {
          cmpConfig = {};
        }
        if (!cmpConfig.containerConfig) {
          cmpConfig.containerConfig = {};
        }
        const ERROR = annotateAndLogError(
          err,
          `PRELOAD_CUSTOM_PROPS`,
          cmpConfig
        );
        cmpConfig.containerConfig.criticalError = getSanitizedError(ERROR);
        resolve(cmpConfig);
      });
  });
};
