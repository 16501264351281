import * as logger from "loglevel";
import { isSSR } from "@front10/utils";
import { withCommonPreloads } from "./commons/commonPreloads";

const preloadCustomPropsExecute = (cmpConfig, clientPromises) => {
  return new Promise((resolve, reject) => {
    const { name, simulateSSR } = cmpConfig;

    let promises = [];
    if (!isSSR(simulateSSR)) {
      promises = clientPromises;
    }

    Promise.all(promises)
      .then(() => {
        resolve(cmpConfig);
      })
      .catch((err) => {
        logger.error(`PRELOAD_CUSTOM_PROPS: ${name}`, err);
        reject(err);
      });
  });
};

const preloadCustomProps = (cmpConfig) => {
  // HOC For all Common Preload Logic
  return withCommonPreloads(preloadCustomPropsExecute, cmpConfig);
};

export default preloadCustomProps;
