const registerComponent = (componentDefinitionInstance, cmpConfig) => {
  if (!window.EM) {
    window.EM = {};
  }
  if (!window.EM.___fcRuntime) {
    window.EM.___fcRuntime = {};
  }
  if (!window.EM.___fcRuntime.___cmpDefinitionsQueue) {
    window.EM.___fcRuntime.___cmpDefinitionsQueue = [];
  }

  function executeCmpDefinition(version) {
    if (window && window.FC && window.FC.registerComponent) {
      window.FC.registerComponent(componentDefinitionInstance, cmpConfig);
    } else {
      console.log(
        "ERROR: window.FC.registerComponent not available in global scope, this will mean that your Component is not going to load properly"
      );
    }
  }
  window.EM.___fcRuntime.___cmpDefinitionsQueue.push({
    name: cmpConfig.name,
    definition: executeCmpDefinition,
  });
};

export { registerComponent };
