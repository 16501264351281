const validateCmpConfig = (cmpConfig) => {
  const validation = { isValid: true, message: "" };
  if (!cmpConfig) {
    return {
      isValid: false,
      data: cmpConfig,
      message: "PRELOAD_CUSTOM_PROPS: **cmpConfig** is not defined",
    };
  }
  if (!cmpConfig.context) {
    return {
      isValid: false,
      data: cmpConfig,
      message: "PRELOAD_CUSTOM_PROPS: **cmpConfig.context** is not defined",
    };
  }
  if (!cmpConfig.containerConfig || !cmpConfig.containerConfig.settings) {
    return {
      isValid: false,
      data: cmpConfig,
      message:
        "PRELOAD_CUSTOM_PROPS: **cmpConfig.containerConfig.settings** is not defined",
    };
  }
  if (!cmpConfig.containerConfig.settings.integrationSettings) {
    return {
      isValid: false,
      data: cmpConfig,
      message:
        "PRELOAD_CUSTOM_PROPS: **cmpConfig.integrationSettings** is not defined",
    };
  }
  if (!cmpConfig.containerConfig.settings.prePopulation) {
    return {
      isValid: false,
      data: cmpConfig,
      message:
        "PRELOAD_CUSTOM_PROPS: **cmpConfig.prepoPulation** is not defined",
    };
  }

  return validation;
};

export default validateCmpConfig;
